<template>
  <div class="flex h-full bg-cream">
    <div class="m-auto w-full">
      <div class="flex items-start justify-center space-x-10">
        <BackButton class="mt-3" @onClick="goBack"/>
        <div class="flex flex-col text-lg mb-2 w-100">
          <p class="heading-l-500 mb-5 capitalize">Activate job</p>
          <p class="body-l-400 mb-4">Are you sure you want to continue?</p>
          <p class="body-l-400">
            Your monthly payment of {{ job.job_amount | currency }} will be paid to the pro each month on this date. Your first payment will be on {{ nextDueDate }}.
          </p>

          <ButtonIcon
            text="Activate the job"
            font-weight="font-normal"
            x-padding="px-4"
            :loading="loading"
            :disabled="loading"
            class="w-full mt-16"
            @onClick="activateJob"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapMutations, mapState } from 'vuex';
import moment from 'moment';
import BackButton from "@/components/shared/buttons/BackButton";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: 'ActivateJobModal',
  components: { BackButton, ButtonIcon },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
    }),

    nextDueDate() {
      return moment().add(1, 'months').format('MMM DD, YYYY')
    },
  },

  methods: {
    ...mapMutations({
      setJob: "jobs/setJob",
      updateItem: "home/updateItem",
      showModal: 'general/showModal',
    }),

    async activateJob(){
      this.loading = true;
      try {
        const { data } = await api.post(`jobs/${this.job.id}/activate`);
        if (data.success) {
            this.$toast.success('Job is active now');
            this.setJob(data.data);
            this.goBack();
            this.updateItem({ id: this.job.id, data: { status: 'active' } });
        }
      } catch (error) {
        console.log(error);
        this.$toast.error('There was an error. Try again later.');
      } finally {
        this.loading = false;
      }
    },

    goBack() {
      this.showModal({ componentName: 'JobModal', props: { jobId: this.job.id }});
    },
  },
}
</script>